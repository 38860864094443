<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("TEMPLATES_MAILS.TEMPLATES_MAILS_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openTemplateMailCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_TEMPLATES_MAILS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("TEMPLATES_MAILS.ADD_TEMPLATE_MAIL") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_TEMPLATES_MAILS)"
              :objectType="'templatesMails'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <template-mail-list-table
        @onViewTemplateMail="openTemplateMailViewModal"
        @onEditTemplateMail="openTemplateMailEditModal"
        @onDeleteTemplateMail="deleteTemplateMail"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewTemplateMailModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewTemplateMailModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'TEMPLATE_MAIL_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("TEMPLATES_MAILS.VIEW_TEMPLATE_MAIL") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openTemplateMail"
                :objectType="'templatesMails'"
                :objectId="openTemplateMail.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openTemplateMailEditModal(openTemplateMail)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="edit"
                  @click="deleteTemplateMail(openTemplateMail)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeTemplateMailModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-template-mail-page
              v-if="openTemplateMail"
              :templateMailId="openTemplateMail.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditTemplateMailModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditTemplateMailModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'TEMPLATE_MAIL'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("TEMPLATES_MAILS.EDIT_TEMPLATE_MAIL") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openTemplateMailViewModal(openTemplateMail)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeTemplateMailModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-template-mail-page
              v-if="openTemplateMail"
              :templateMailId="openTemplateMail.id"
              @onViewTemplateMail="openTemplateMailViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddTemplateMailModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddTemplateMailModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'TEMPLATE_MAIL'"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("TEMPLATES_MAILS.ADD_TEMPLATE_MAIL") }}
            </h1>
            <button class="close" @click="closeTemplateMailModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-template-mail-page
              @onViewTemplateMail="openTemplateMailViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import TemplateMailListTable from "./partials/TemplateMailListTable.vue";
import EditTemplateMailPage from "./components/EditTemplateMailComponent.vue";
import AddTemplateMailPage from "./components/AddTemplateMailComponent.vue";
import ViewTemplateMailPage from "./components/ViewTemplateMailComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    TemplateMailListTable,
    NotificationSubscription,
    EditTemplateMailPage,
    AddTemplateMailPage,
    ViewTemplateMailPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const templateMailId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewTemplateMailModalOpened = false;
    let isEditTemplateMailModalOpened = false;
    let isAddTemplateMailModalOpened = false;
    let openTemplateMail = null;
    if (templateMailId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewTemplateMailModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditTemplateMailModalOpened = true;
      }
      openTemplateMail = { id: templateMailId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddTemplateMailModalOpened = true;
    }
    return {
      isViewTemplateMailModalOpened: isViewTemplateMailModalOpened,
      isEditTemplateMailModalOpened: isEditTemplateMailModalOpened,
      isAddTemplateMailModalOpened: isAddTemplateMailModalOpened,
      openTemplateMail: openTemplateMail,
      renderKey: 1,
    };
  },

  methods: {
    openTemplateMailCreateModal() {
      this.closeTemplateMailModal();
      this.isAddTemplateMailModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TemplatesMails",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openTemplateMailViewModal(templateMail, reRender = false) {
      this.closeTemplateMailModal();
      this.openTemplateMail = templateMail;
      this.isViewTemplateMailModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TemplatesMails",
          query: { id: this.openTemplateMail.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openTemplateMailEditModal(templateMail) {
      this.closeTemplateMailModal();
      this.openTemplateMail = templateMail;
      this.isEditTemplateMailModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TemplatesMails",
          query: { id: this.openTemplateMail.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeTemplateMailModal() {
      this.isAddTemplateMailModalOpened = false;
      this.isViewTemplateMailModalOpened = false;
      this.isEditTemplateMailModalOpened = false;
      this.openTemplateMail = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TemplatesMails",
          query: {},
        }).href
      );
    },

    async deleteTemplateMail(templateMail) {
      const confirmation = await swal.fire({
        title: this.$t("TEMPLATES_MAILS.DELETE_THIS_TEMPLATE_MAIL"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("templatesMails/destroy", templateMail.id);
          this.renderKey++;
          this.closeTemplateMailModal();
          this.$notify({
            type: "success",
            message: this.$t("TEMPLATES_MAILS.TEMPLATE_MAIL_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
