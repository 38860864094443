export default {
  type: "templates-mails",
  subject: null,
  title: null,
  from: null,
  recipients: null,
  cc: null,
  bcc: null,
  content: "",
  relationshipNames: ["typesMail"],
  typesMail: {
    type: "types-mails",
    id: null,
  },
};
